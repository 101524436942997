.checkout-btn,
.coupon-code {
  margin-top: 12px !important;
}

.coupon-code {
  display: flex;
  justify-content: space-between;
}

.qty-plus-minus {
  width: 50% !important;
}
